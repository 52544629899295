<template>
  <div class="supplierStepThreeCmpt">
    <!-- 主体区域 -->
    <div class="bodyArea">
      <div class="title">运单信息进度</div>
      <!-- 步骤条 -->
      <Step :activeList="[1, 1, 1, 0]" :role="3"></Step>
      <!-- 货主已选字段展示 -->
      <el-form class="waybillForm" :model="waybillForm" label-width="120px">
        <!-- 已选地区 -->
        <el-form-item label="已选地区" prop="address" label-width="80px">
          <el-input v-model="waybillForm.address" disabled></el-input>
        </el-form-item>
        <!-- 已选服务商 -->
        <el-form-item label="已选服务商" prop="provider">
          <el-input v-model="waybillForm.provider" disabled></el-input>
        </el-form-item>
        <!-- 已选发票抬头 -->
        <el-form-item label="已选发票抬头" prop="invoiceTitle">
          <el-input v-model="waybillForm.invoiceTitle" disabled></el-input>
        </el-form-item>
        <!-- 已选发票类型 -->
        <el-form-item label="已选发票类型" prop="invoiceType">
          <el-input v-model="waybillForm.invoiceType" disabled></el-input>
        </el-form-item>
      </el-form>
      <!-- 合计 -->
      <div class="settleBox">
        <el-row>
          <el-col :span="5">开票申请时间：<strong>{{ waybillForm.applyDateTime }}</strong></el-col>
          <el-col :span="5">运单数量(条)：<strong>{{ waybillForm.wayBillCount }}</strong></el-col>
          <el-col :span="5">运费(元)：/</el-col>
          <el-col :span="9">服务费/税费(元)：<strong>￥{{ waybillForm.taxes }}</strong></el-col>
        </el-row>
        <el-row>
          <el-col :span="5">运单总金额(元)：<strong>￥{{ waybillForm.totalAmount }}</strong></el-col>
          <el-col :span="5">货主单位：<strong>{{ waybillForm.invoiceTitleName }}</strong></el-col>
          <el-col :span="5">货主税号：<strong>{{ waybillForm.invoiceTitleCreditCode }}</strong></el-col>
          <el-col :span="9">货主地址：<strong>{{ waybillForm.invoiceTitleAddress }}</strong></el-col>
        </el-row>
        <el-row>
          <el-col :span="5">货主电话：<strong>{{ waybillForm.invoiceTitlePhone }}</strong></el-col>
          <el-col :span="5">货主账号：<strong>{{ waybillForm.invoiceTitleBankerNumber }}</strong></el-col>
          <el-col :span="5" v-if="waybillForm.invoiceKindTec">专票普票：<strong>运费-{{ waybillForm.invoiceKind }}
              技术服务费-{{ waybillForm.invoiceKindTec }}</strong></el-col>
          <el-col :span="5" v-else>专票普票：<strong>{{ waybillForm.invoiceKind }}</strong></el-col>
          <el-col :span="9">货主开户行：<strong>{{ waybillForm.invoiceTitleBankerName }}</strong></el-col>
        </el-row>
        <el-row>
          <el-col :span="5">支付方式：<strong>{{ waybillForm.payType }}</strong></el-col>
        </el-row>
        <el-row>
          <el-col :span="24">开票备注：{{ KPRemark }}</el-col>
        </el-row>
      </div>
      <!-- 货物承运 -->
      <el-table v-if="flag.waybillType == '01' && flag.taskSceneTypeID == '1'" v-loading="flag.dialogLoading"
        :data="tableData" :header-cell-style="{ color: '#666', background: '#f0f0f0' }" max-heght="500">
        <el-table-column fixed align="center" prop="index" label="序号" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="WaybillNumber" label="运单号" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="CarriageBillID" label="客户单号" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="InvoiceType" label="发票类型" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="waybillType" label="业务类型" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="TaskSceneName" label="场景类型" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="Shipper_name" label="货主单位" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="DriverName" label="司机姓名" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="Phone" label="司机手机号码" width="200" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="DriverIDCard" label="司机身份证号码" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color:blue;cursor: pointer;" @click="openDerverInfo(scope.row, scope.row.DriverUserID)">
              {{ scope.row.DriverIDCard }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="PayName" label="收款人姓名" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="Recy_payment_idcard" label="收款人手机号码" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="PayeeIDCard" label="收款人身份证号码" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color:blue;cursor: pointer;" @click="openDerverInfo(scope.row, scope.row, scope.row.PayeeUserID)">
              {{ scope.row.PayeeIDCard }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="LeaderName" label="车队长姓名" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="LeaderPhone" label="车队长手机号码" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="LeaderIDCard" label="车队长身份证号码" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color:blue;cursor: pointer;" @click="openDerverInfo(scope.row, scope.row.LeaderUserID)">
              {{ scope.row.LeaderIDCard }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="LicensePlate" label="车牌" width="200">
          <template slot-scope="scope">
            <div style="color:blue;cursor: pointer;" @click="openVehicleInfo(scope.row)">
              {{ scope.row.LicensePlate }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="Net_weight" label="装货净重(吨)" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="Product_name" label="货品名称" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="Product_unit" label="货品单位" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="Loading_time" label="装货时间" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="Submission_time" label="签收时间" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="Start_addrees" label="起始地" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color:blue;cursor: pointer;" @click="openMap(scope.row)">
              {{ scope.row.Start_addrees }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="Arrivals" label="到达地" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color:blue;cursor: pointer;" @click="openMap(scope.row)">
              {{ scope.row.Arrivals }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="Number_allocated_drivers" label="司机装货数量" width="200"
          show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="Driver_transportation_price" label="司机运输单价（人民币）" width="200">
          <template slot-scope="scope">
            <div style="color:#FF8B17;">
              {{ scope.row.Driver_transportation_price | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="amount_count" label="运费" width="200">
          <template slot-scope="scope">
            <div style="color:#FF8B17;">
              /
              <!-- {{scope.row.amount_count | formatMoney}} -->
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="ExpensesOfTaxation" label="服务费/税费" width="200">
          <template slot-scope="scope">
            <div style="color:#FF8B17;">
              {{ scope.row.ExpensesOfTaxation | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="TotalAmountIncludeTaxes" label="运单总金额" width="200">
          <template slot-scope="scope">
            <div style="color:#FF8B17;">
              {{ scope.row.TotalAmountIncludeTaxes | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="Unloading_net_weight" label="卸货净重(吨)" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="RiskPath" label="风控路径" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="PayMethod" label="支付方式" width="200" show-overflow-tooltip>
        </el-table-column>
        <!-- <el-table-column align="center" prop="InvoiceAmountType" label="开票金额" width="200" show-overflow-tooltip>
        </el-table-column> -->
        <el-table-column align="center" prop="SourceBillId" label="溯源运单号" width="200" show-overflow-tooltip>
        </el-table-column>
      </el-table>
      <!-- 土石方 -->
      <el-table v-if="flag.waybillType == '01' && flag.taskSceneTypeID == '6'" v-loading="flag.dialogLoading"
        :data="tableData" :header-cell-style="{ color: '#666', background: '#f0f0f0' }">
        <el-table-column fixed align="center" prop="index" label="序号" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="WaybillNumber" label="运单号" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="CarriageBillID" label="客户单号" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="InvoiceType" label="发票类型" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="waybillType" label="业务类型" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="TaskSceneName" label="场景类型" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="Shipper_name" label="货主单位" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="DriverName" label="司机姓名" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="Phone" label="司机手机号码" width="200" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="DriverIDCard" label="司机身份证号码" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color:blue;cursor: pointer;" @click="openDerverInfo(scope.row, scope.row.DriverUserID)">
              {{ scope.row.DriverIDCard }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="PayName" label="收款人姓名" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="Recy_payment_idcard" label="收款人手机号码" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="PayeeIDCard" label="收款人身份证号码" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color:blue;cursor: pointer;" @click="openDerverInfo(scope.row, scope.row.PayeeUserID)">
              {{ scope.row.PayeeIDCard }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="LeaderName" label="车队长姓名" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="LeaderPhone" label="车队长手机号码" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="LeaderIDCard" label="车队长身份证号码" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color:blue;cursor: pointer;" @click="openDerverInfo(scope.row, scope.row.LeaderUserID)">
              {{ scope.row.LeaderIDCard }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="LicensePlate" label="车牌" width="200">
          <template slot-scope="scope">
            <div style="color:blue;cursor: pointer;" @click="openVehicleInfo(scope.row)">
              {{ scope.row.LicensePlate }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="Net_weight" label="装货净重(吨)" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="Product_name" label="货品名称" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="Product_unit" label="货品单位" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="Loading_time" label="装货时间" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="Submission_time" label="签收时间" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="Start_addrees" label="起始地" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color:blue;cursor: pointer;" @click="openMap(scope.row)">
              {{ scope.row.Start_addrees }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="Arrivals" label="到达地" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color:blue;cursor: pointer;" @click="openMap(scope.row)">
              {{ scope.row.Arrivals }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="Number_allocated_drivers" label="司机装货数量" width="200"
          show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="Driver_transportation_price" label="司机运输单价（人民币）" width="200">
          <template slot-scope="scope">
            <div style="color:#FF8B17;">
              {{ scope.row.Driver_transportation_price | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="amount_count" label="运费" width="200">
          <template slot-scope="scope">
            <div style="color:#FF8B17;">
              /
              <!-- {{scope.row.amount_count | formatMoney}} -->
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="ExpensesOfTaxation" label="服务费/税费" width="200">
          <template slot-scope="scope">
            <div style="color:#FF8B17;">
              {{ scope.row.ExpensesOfTaxation | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="TotalAmountIncludeTaxes" label="运单总金额" width="200">
          <template slot-scope="scope">
            <div style="color:#FF8B17;">
              {{ scope.row.TotalAmountIncludeTaxes | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="Unloading_net_weight" label="卸货净重(吨)" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="RiskPath" label="风控路径" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="PayMethod" label="支付方式" width="200" show-overflow-tooltip>
        </el-table-column>
        <!-- <el-table-column align="center" prop="InvoiceAmountType" label="开票金额" width="200" show-overflow-tooltip>
        </el-table-column> -->
        <el-table-column align="center" prop="SourceBillId" label="溯源运单号" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="PayeeRemark" label="收款人备注" width="200" show-overflow-tooltip>
        </el-table-column>
      </el-table>
      <!-- 交通运输 -->
      <el-table
        v-if="(flag.waybillType == '02' && flag.taskSceneTypeID == '3') || $entrustSceneIds.includes(Number(flag.taskSceneTypeID))"
        v-loading="flag.dialogLoading" :data="tableData" :header-cell-style="{ color: '#666', background: '#f0f0f0' }">
        <el-table-column fixed align="center" prop="index" label="序号" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="WaybillNumber" label="运单号" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="CarriageBillID" label="客户单号" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="InvoiceType" label="发票类型" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="waybillType" label="业务类型" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="TaskSceneName" label="场景类型" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="Shipper_name" label="货主单位" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="DriverName" label="司机姓名" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="Phone" label="司机手机号码" width="200" show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="DriverIDCard" label="司机身份证号码" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color:blue;cursor: pointer;" @click="openDerverInfo(scope.row, scope.row.DriverUserID)">
              {{ scope.row.DriverIDCard }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="PayName" label="收款人姓名" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="Recy_payment_idcard" label="收款人手机号码" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="PayeeIDCard" label="收款人身份证号码" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color:blue;cursor: pointer;" @click="openDerverInfo(scope.row, scope.row.PayeeUserID)">
              {{ scope.row.PayeeIDCard }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="LeaderName" label="车队长姓名" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="LeaderPhone" label="车队长手机号码" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="LeaderIDCard" label="车队长身份证号码" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color:blue;cursor: pointer;" @click="openDerverInfo(scope.row, scope.row.LeaderUserID)">
              {{ scope.row.LeaderIDCard }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="LicensePlate" label="车牌" width="200">
          <template slot-scope="scope">
            <div style="color:blue;cursor: pointer;" @click="openVehicleInfo(scope.row)">
              {{ scope.row.LicensePlate }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="Net_weight" label="装货净重(吨)" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="Product_name" label="货品名称" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="Product_unit" label="货品单位" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="Loading_time" label="装货时间" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="Submission_time" label="签收时间" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="Start_addrees" label="起始地" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color:blue;cursor: pointer;" @click="openMap(scope.row)">
              {{ scope.row.Start_addrees }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="Arrivals" label="到达地" width="200" show-overflow-tooltip>
          <template slot-scope="scope">
            <div style="color:blue;cursor: pointer;" @click="openMap(scope.row)">
              {{ scope.row.Arrivals }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="Number_allocated_drivers" label="司机装货数量" width="200"
          show-overflow-tooltip></el-table-column>
        <el-table-column align="center" prop="Driver_transportation_price" label="司机运输单价（人民币）" width="200">
          <template slot-scope="scope">
            <div style="color:#FF8B17;">
              {{ scope.row.Driver_transportation_price | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="amount_count" label="运费" width="200">
          <template slot-scope="scope">
            <div style="color:#FF8B17;">
              /
              <!-- {{scope.row.amount_count | formatMoney}} -->
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="ExpensesOfTaxation" label="服务费/税费" width="200">
          <template slot-scope="scope">
            <div style="color:#FF8B17;">
              {{ scope.row.ExpensesOfTaxation | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="TotalAmountIncludeTaxes" label="运单总金额" width="200">
          <template slot-scope="scope">
            <div style="color:#FF8B17;">
              {{ scope.row.TotalAmountIncludeTaxes | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="Entrusted_Collection_Amount" label="委托代开运费" width="200">
          <template slot-scope="scope">
            <div style="color:#FF8B17;">
              <!-- {{scope.row.Entrusted_Collection_Amount | formatMoney}} -->
              /
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="TaxesDZ" label="委托代开服务费/税费" width="200">
          <template slot-scope="scope">
            <div style="color:#FF8B17;">
              {{ scope.row.TaxesDZ | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="AmountDZ" label="委托代开运单总金额" width="200">
          <template slot-scope="scope">
            <div style="color:#FF8B17;">
              {{ scope.row.AmountDZ | formatMoney }}
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="Unloading_net_weight" label="卸货净重(吨)" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="RiskPath" label="风控路径" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="PayMethod" label="支付方式" width="200" show-overflow-tooltip>
        </el-table-column>
        <!-- <el-table-column align="center" prop="InvoiceAmountType" label="开票金额" width="200" show-overflow-tooltip>
        </el-table-column> -->
        <el-table-column align="center" prop="SourceBillId" label="溯源运单号" width="200" show-overflow-tooltip>
        </el-table-column>
        <el-table-column align="center" prop="PayeeRemark" label="收款人备注" width="200" show-overflow-tooltip>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination background class="pagination" @current-change="handleCurrentChange"
        :current-page.sync="pagination.page" :page-size="pagination.pagesize" layout="total, prev, pager, next, jumper"
        :total="pagination.total">
      </el-pagination>
      <!-- 右下角按钮组 -->
      <div class="funcs">
        <el-button size="mini" @click="goBack" icon="el-icon-arrow-left">返回</el-button>
        <el-button type="primary" size="mini" @click="flag.rejectModal = true">放弃开票</el-button>
        <el-button type="primary" size="mini" @click="agree">同意开票</el-button>
        <el-button type="primary" size="mini" @click="downBillsDetails" :disabled="flag.downBillsDetailsIng">下载税务表格
        </el-button>
      </div>
      <!-- 地图dialog -->
      <el-dialog width="800px" title="查看地图" :visible.sync="flag.showMap" append-to-body>
        <!-- 地图组件 -->
        <TXmap style="height:600px;" v-if="flag.showMap" :list="mapList" ref="map"></TXmap>
      </el-dialog>
      <!-- 车辆信息dialog -->
      <el-dialog width="1300px" title="车辆信息" :visible.sync="flag.showVehicleInfo" append-to-body>
        <VehicleInfo ref="vehicleInfo" v-if="flag.showVehicleInfo" :vehicleInfo="vehicleInfo"></VehicleInfo>
      </el-dialog>
      <!-- 司机信息组件 -->
      <el-dialog width="1300px" title="司机信息" :visible.sync="flag.showDriverInfo" append-to-body>
        <DriverInfo :driverInfo="driverInfo" />
      </el-dialog>
      <!-- 服务商放弃开票 -->
      <el-dialog :visible.sync="flag.rejectModal" width="700px" center>
        <p style="margin: 8px 0">1.您点击【放弃】，则不再为该货主开票，本结算单将终止流程。</p>
        <p style="margin: 8px 0">2.您的每月最大可开票额度会自动补充。</p>
        <div style="text-align: center;">
          <el-button type="primary" size="mini" @click="flag.rejectModal = false">取消</el-button>
          <el-button type="primary" size="mini" @click="providerGiveUpInvoice">放弃</el-button>
        </div>
      </el-dialog>
      <!-- 驳回意见dialog
      <el-dialog width="700px" class="stepThreeRejectDialog" title="意见" :visible.sync="flag.rejectModal" append-to-body>
        <el-input type="textarea" maxlength="200" placeholder="请输入处理意见，限制200个汉字" v-model="rejectContent"></el-input>
        <el-checkbox-group v-model="checkFastReplyList" style="margin-top: 16px;">
          <el-checkbox :label="item.Name" v-for="item in fastReplyList" :key="item.Code" style="padding: 5px 0">
          </el-checkbox>
        </el-checkbox-group>
        <div style="display: flex; justify-content: center; margin-top: 16px;">
          <el-button size="mini" @click="flag.rejectModal = false">取消</el-button>
          <el-button type="primary" :loading="flag.reject" size="mini" @click="reject">发送</el-button>
        </div>
      </el-dialog> -->
    </div>
    <!-- 货主已付款dialog -->
    <el-dialog class="payed" title="货主已付款，请上传发票信息" :visible.sync="flag.payedDialog" width="700px" :show-close="false"
      :close-on-press-escape="false" :close-on-click-modal="false" center>
      <p>备注：{{ KPRemark1 }}</p>
      <p>
        请您根据结算单与运单详情，合理优化发票面额组合，开具发票后，将发票电子文件上传。
      </p>
      <p style="color: red">注：电子文件或电子照片保持清晰完整！</p>
      <div style="text-align: center">
        <el-button type="primary" size="mini" @click="goBack">确定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getPaymentInfo, RefundWaybill } from '@/api/waybill/waybillManage/index'
import { billsInfoDeatils, refuToBill, getDataDict, providerGiveUpInvoice, ownerPays } from '@/api/supplierWaybill/basicWaybill/makeInvoice'
import { getMapPath, getCarInfo, getDriverDetails } from '@/api/common/common'
import { mapGetters } from 'vuex'
import Step from '@/components/businessCmpt/step'
import TXmap from '@/components/commonCmpt/TXmap'
import VehicleInfo from '@/components/businessCmpt/vehicleInfo'
import DriverInfo from '@/components/businessCmpt/driverInfo'
export default {
  data() {
    return {
      flag: {
        isGoOn: false, //是否可可以点击页面的内容
        showMap: false, //显示地图
        showVehicleInfo: false, //显示车辆信息
        showDriverInfo: false, //显示司机信息
        waybillType: '', //业务类型 货物承运 委托代开
        taskSceneTypeID: null, //场景类型
        downBillsDetailsIng: false, //下载表格中
        dialogLoading: false, //dialog弹窗是否是loading状态
        rejectModal: false, //拒绝理由modal
        reject: false, //拒绝中
        payedDialog: false, // 付款成功状态
      },
      KPRemark1: '',// 开票备注
      pagination: { //分页控件相关参数
        page: 1, //当前所处的页码
        pagesize: 10, //每次请求的数量
        total: 0, //总条数
      },
      waybillForm: {
        address: '', //已选地区
        provider: '', //已选服务商
        invoiceTitle: '', //已选发票抬头
        invoiceType: '', //已选发票类型
        applyDateTime: '', //申请时间
        wayBillCount: '', //运单条数
        transFee: '', //总运费
        taxes: '', //总税费
        totalAmount: '', //支付总金额
        selectedAddress: '', //货主目的地
        rate: '', //费率
        invoiceTitleAddress: '', //货主地址
        invoiceTitleBankerName: '', //货主开户行
        invoiceTitleBankerNumber: '', //货主账号
        invoiceTitleCreditCode: '', //货主税号
        invoiceTitleName: '',//货主单位
        invoiceTitlePhone: '', //货主电话
      },
      tableData: [], //运单列表
      invoiceTaskID: '', //开票任务单号
      vehicleInfo: {}, //车辆信息
      driverInfo: {}, //司机信息
      mapList: [], //轨迹列表
      rejectContent: '', //拒绝意见
      fastReplyList: [], //快捷回复列表
      checkFastReplyList: [],//选中的快捷回复
      KPRemark: '',//开票备注
    }
  },
  methods: {
    //获取合计数据
    getPaymentInfo() {
      let params = {
        InvoiceTaskID: this.invoiceTaskID,
        Type: 0 //服务商
      }
      getPaymentInfo(params).then(res => {
        this.waybillForm.address = res.payInfo.SelectedAddress
        this.waybillForm.provider = res.payInfo.SelectedProvide
        this.waybillForm.invoiceTitle = res.payInfo.InvoiceTile
        this.waybillForm.invoiceKind = res.payInfo.InvoiceKind
        this.waybillForm.invoiceKindTec = res.payInfo.InvoiceKindTec
        this.waybillForm.payType = res.payInfo.PayType
        this.waybillForm.applyDateTime = res.payInfo.ApplyDateTime
        this.waybillForm.wayBillCount = res.payInfo.WayBillCount
        this.waybillForm.transFee = res.payInfo.TransFee
        this.waybillForm.taxes = res.payInfo.Taxes
        this.waybillForm.totalAmount = res.payInfo.TotalAmount
        this.waybillForm.selectedAddress = res.payInfo.SelectedAddress
        this.waybillForm.rate = res.payInfo.Rate
        this.waybillForm.invoiceType = res.payInfo.InvoiceType
        this.waybillForm.invoiceAmoutType = res.payInfo.InvoiceAmoutType
        this.KPRemark = res.payInfo.KPRemark
        this.waybillForm.invoiceTitleAddress = res.Consign.InvoiceTitleAddress //货主地址
        this.waybillForm.invoiceTitleBankerName = res.Consign.InvoiceTitleBankerName //货主开户行
        this.waybillForm.invoiceTitleBankerNumber = res.Consign.InvoiceTitleBankerNumber //货主账号
        this.waybillForm.invoiceTitleCreditCode = res.Consign.InvoiceTitleCreditCode //货主税号
        this.waybillForm.invoiceTitleName = res.Consign.InvoiceTitleName //货主单位
        this.waybillForm.invoiceTitlePhone = res.Consign.InvoiceTitlePhone //货主电话
      })
    },
    // 返回待办
    goBack() {
      this.flag.payedDialog = false
      this.$router.push({
        path: this.$route.query.pagetype == 'payApply' ? "/commission/payApply/index" : "/commission/pendingManage/pendingDetail",
        query: { currentStep: this.$route.query.currentStep, enterpriseFullName: this.$route.query.enterpriseFullName, pagetype: this.$route.query.pagetype },
      });
    },
    //获取运单列表
    billsInfoDeatils() {
      let params = {
        InvoiceTaskID: this.invoiceTaskID,
        PageIndex: this.pagination.page,
        PageSize: this.pagination.pagesize
      }
      billsInfoDeatils({ json: JSON.stringify(params) }).then(res => {
        this.tableData = res.invoices.map((item, index) => {
          item.index = index + 1
          return item
        })
        this.pagination.total = Number(res.totalRowCount)
        //确定业务类型和场景类型
        this.flag.waybillType = res.invoices[0].TaskType
        this.flag.taskSceneTypeID = res.invoices[0].TaskSceneTypeID
      })
    },
    //打开车辆信息
    openVehicleInfo(item) {
      this.flag.dialogLoading = true
      let params = {
        CarNumber: item.LicensePlate ? item.LicensePlate : item.CarNumber,
      }
      let data = {}
      getCarInfo(params).then(res => {
        data = Object.assign({}, res.data.vehicle_baseInfo, res.data.vehicle_confirmInfo)
        this.vehicleInfo = data
        this.flag.showVehicleInfo = true
      }).finally(() => {
        this.flag.dialogLoading = false
      })
    },
    openMap(item) {
      this.flag.dialogLoading = true
      let params = {
        wayBillId: item.WaybillNumber,
        searchWord: item.LicensePlate,
        stDate: item.Loading_time,
        edDate: item.Submission_time,
        coordType: 2
      }
      getMapPath({ json: JSON.stringify(params) }).then(res => {
        this.mapList = res.Track
        this.flag.showMap = true
      }).finally(() => {
        this.flag.dialogLoading = false
      })
    },
    //查看司机信息
    openDerverInfo(item, UserID) {
      this.flag.dialogLoading = true
      let data = {}
      getDriverDetails({ UserID: UserID, AscriptionUserID: item.UserID }).then(res => {
        data = Object.assign({}, res.data.driver_baseInfo, res.data.driver_confirmInfo)
        this.driverInfo = data
        this.flag.showDriverInfo = true
      }).finally(() => {
        this.flag.dialogLoading = false
      })
    },
    //分页回调
    handleCurrentChange(e) {
      this.pagination.page = e
      this.billsInfoDeatils()
    },
    //下载税务表格
    downBillsDetails() {
      this.flag.downBillsDetailsIng = true
      let params = {
        invoiceTaskId: this.getInvoiceTaskID
      }
      RefundWaybill(params).then(res => {
        window.location.href = res.data
        this.flag.downBillsDetailsIng = false
      })
    },
    // 放弃开票
    providerGiveUpInvoice() {
      let params = {
        taskId: this.invoiceTaskID
      }
      providerGiveUpInvoice(params).then(res => {
        this.$router.push('/commission/pendingManage/index')
      })
    },
    //拒绝
    // reject() {
    //   this.flag.reject = true
    //   let arr = []
    //   this.tableData.forEach(item => {
    //     arr.push(this.invoiceTaskID)
    //   })
    //   let params = {
    //     status: 'no',
    //     list: arr,
    //     remark: this.rejectContent,
    //     quickReply: this.checkFastReplyList.join('_')
    //   }
    //   refuToBill({ Json: JSON.stringify(params) }).then(res => {
    //     this.$message({
    //       type: 'success',
    //       message: '您已拒绝了货主的开票申请',
    //       duration: 8000
    //     })
    //     this.$router.push('/commission/pendingManage/index')
    //   }).finally(() => {
    //     this.flag.reject = false
    //   })
    // },
    //同意
    agree() {
      const loading = this.$loading({
        lock: true,
        text: '请求中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      let params = {
        status: 'yes',
        list: [this.invoiceTaskID]
      }
      refuToBill({ Json: JSON.stringify(params) }).then(res => {
        ownerPays({
          invoiceTaskID: this.invoiceTaskID
        }).then(ress => {
          this.KPRemark1 = ress.KPRemark;
          if (ress.IsSkipPay) {
            this.flag.payedDialog = true;
          } else {
            this.$emit('stepChange', 4)
          }
          loading.close()
        }).catch(() => {
          loading.close()
        })
      }).catch(() => {
        loading.close()
      })
    },
    //获取快捷回复列表
    getFastReplyList() {
      let params = {
        type: 58
      }
      getDataDict(params).then(res => {
        this.fastReplyList = res.patterSetInfo
      })
    },
  },
  computed: {
    ...mapGetters(['getInvoiceTaskID']),
  },
  created() {
    this.invoiceTaskID = this.getInvoiceTaskID
    //获取运单列表
    this.getPaymentInfo()
    this.billsInfoDeatils()
    this.getFastReplyList()
  },
  components: {
    Step,
    TXmap,
    VehicleInfo,
    DriverInfo
  },
}
</script>

<style lang="scss" scoped>
@import '../../../../../assets/style/variable.scss';

.supplierStepThreeCmpt {
  .payed {
    p {
      text-align: center;
      margin: 10px 0;
    }
  }

  .waybillForm {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 10px;

    .el-form-item {
      margin: 0;
      flex: 1;
    }
  }

  .sumArea {
    display: flex;
    align-items: center;
    margin: 16px 0;

    p {
      width: 150px;
      margin-right: 10px;
      font-size: 20px;
      font-weight: 700;
    }

    .total {
      background: $primary;
      display: flex;
      flex-wrap: wrap;
      color: white;
      padding: 10px;
      border-radius: 5px;

      div {
        width: 20%;
      }
    }
  }

  .bodyArea {
    background: white;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    overflow: auto;
    padding: 10px;
    height: calc(100vh - 106px);

    .title {
      color: #2081ff;
      position: relative;
      text-indent: 15px;
      font-size: 14px;
      border-bottom: 1px solid $borderGray;
      padding: 20px 0;
      margin-bottom: 20px;

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 0;
        transform: translateY(-50%);
        width: 3px;
        height: 14px;
        border-radius: 5px;
        background: $primary;
      }
    }

    .settleBox {
      margin-bottom: 10px;
      padding: 10px 16px;
      background-color: #f0f0f0;
      color: #666;
    }

    .funcs {
      text-align: center;
      margin-bottom: 16px;
    }

    .pagination {
      text-align: left;
      margin: 10px 0;
    }
  }
}
</style>
<style>
/* 卡片样式 */
.supplierStepThreeCmpt .el-card__body {
  display: flex;
  justify-content: space-around;
}

/* 复选框样式 */
.stepThreeRejectDialog .el-checkbox {
  display: block !important;
}
</style>